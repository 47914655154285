import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="To-faktor autentisering for Sign-in with Apple" />
    <div className="longtext">
      <h2>To-faktor autentisering for Sign-in with Apple</h2>
      <p>
        Dersom du benytter Sign-in with Apple må du ha aktivert såkalt to-faktor
        autentisering på din Apple ID. Dette er et krav fra Apple sin side.
      </p>
      <p>
        Å aktivere dette gir deg en ekstra sikkerhet på telefonen, samtidig som
        du kan bruke Sign-in with Apple i alle apper som støtter dette - ikke
        bare Mente.
      </p>
      <p>
        <a href="https://support.apple.com/en-us/HT204915" target="_blank">
          Se Apple sin beskrivelse av hvordan du aktiver dette
        </a>
      </p>
    </div>
  </Layout>
)

export default Page
